import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import Card from "components/Card";
import Table from "components/Table";
import COLORS from "constants/Colors";
import styled from "styled-components";
import { VARIANTS } from "constants/Typography";
import { Column } from "react-table";

export const TableWrapper = styled.div`
  .table {
    font-size: ${VARIANTS.body4.size};
    display: inline-block;
    width: 100%;
    box-sizing: border-box;

    .body {
      position: -webkit-sticky;
      position: sticky;
      max-height: 400px;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        display: initial;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(83, 83, 83, 0.5);
      }

      ::-webkit-scrollbar-corner {
        background-color: ${({ theme }) => theme.COLORS.Light};
      }
    }

    .th {
      padding: 12px 10px 8px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
      color: ${({ theme }) => theme.COLORS.DarkGray};
      font-weight: 500;
      font-size: 14px;
      word-break: break-word;
      line-height: 20px;
      width: 100%;
    }

    .td {
      width: 100%;
      padding: 12px 10px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
      background-color: white;
    }

    .tr {
      width: 100%;
      display: flex;

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-height: none;
  }
`;

const StyledCard = styled(Card)`
  .table-header {
    margin-bottom: 16px;
  }
`;

type PriceRow = {
  weight: string;
  price: number;
};

const ThaipostPriceTable: React.FC = () => {
  const { t } = useTranslation();

  const priceData: PriceRow[] = [
    { weight: "100", price: 19 },
    { weight: "250", price: 19 },
    { weight: "500", price: 19 },
    { weight: "750", price: 19 },
    { weight: "1,000", price: 19 },
    { weight: "1,500", price: 25 },
    { weight: "2,000", price: 25 },
    { weight: "2,500", price: 37 },
    { weight: "3,000", price: 37 },
    { weight: "3,500", price: 47 },
    { weight: "4,000", price: 47 },
    { weight: "4,500", price: 57 },
    { weight: "5,000", price: 57 },
    { weight: "5,500", price: 70 },
    { weight: "6,000", price: 70 },
    { weight: "6,500", price: 80 },
    { weight: "7,000", price: 80 },
    { weight: "7,500", price: 90 },
    { weight: "8,000", price: 90 },
    { weight: "8,500", price: 100 },
    { weight: "9,000", price: 100 },
    { weight: "9,500", price: 110 },
    { weight: "10,000", price: 110 },
    { weight: "11,000", price: 120 },
    { weight: "12,000", price: 130 },
    { weight: "13,000", price: 140 },
    { weight: "14,000", price: 150 },
    { weight: "15,000", price: 160 },
    { weight: "16,000", price: 170 },
    { weight: "17,000", price: 180 },
    { weight: "18,000", price: 190 },
    { weight: "19,000", price: 200 },
    { weight: "20,000", price: 210 }
  ];

  const columns = [
    {
      Header: t("Weight (g)"),
      accessor: "weight",
      Cell: ({ value }: { value: string }) => (
        <Typography variant="body2" color={COLORS.DarkMed}>
          {value}
        </Typography>
      )
    },
    {
      Header: t("Price (THB)"),
      accessor: "price", 
      Cell: ({ value }: { value: number }) => (
        <Typography variant="body2" color={COLORS.DarkMed}>
          {value}
        </Typography>
      )
    }
  ];

  return (
    <StyledCard fullWidth padding="24px" className="mt-4">
      <Typography variant="title4" color={COLORS.DarkMed} className="table-header">
        {t("Thaipost Price Table")}
      </Typography>
      <TableWrapper>
        <div className="table-container">
          <Table
            columns={columns as Column<PriceRow>[]}
            data={priceData}
          />
        </div>
      </TableWrapper>
    </StyledCard>
  );
};

export default ThaipostPriceTable;