import styled from "styled-components";
import { COLORS } from "constants/Colors";

export const ModalContent = styled.div`
  max-width: 500px;
  padding: 24px;
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 32px 0;
`;

export const LabelPreview = styled.div<{ width: number; height: number }>`
  width: ${props => Math.min(props.width * 2, 200)}px;
  height: ${props => Math.min(props.height * 2, 200)}px;
  border: 2px solid ${COLORS.Primary};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.White};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:after {
    content: "${props => props.width} x ${props => props.height} mm";
    color: ${COLORS.Primary};
    font-size: 14px;
    position: absolute;
    width: 100%;
    text-align: center;
  }

  &:before {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: 1px dashed ${COLORS.Primary};
    opacity: 0.5;
  }
`;

export const SelectWrapper = styled.div`
  .select__control {
    border-radius: 8px;
    border-color: ${COLORS.Blue[300]};
    &:hover {
      border-color: ${COLORS.Primary};
    }
  }
`;