import moment from "moment";
import { FILTER_ID } from "domain/Report/ReportFilter/types";

export const DEFAULT_VALUE = "-";
export const DEFAULT_PAGE_START = 1;
export const ROWS_PER_PAGE = 10;
export const ROWS_PER_PAGES = [ROWS_PER_PAGE, 20];
export const DEFAULT_IMG = "assets/no-img-available.png";
export const EXPAND_CELL_WIDTH = 60;
export const LIMIT_ID_SELECTED = 20000;

export const INITIAL_FILTER_LISTS = [
  {
    id: FILTER_ID.DATE_RANGE,
    dateRange: {
      start: moment().subtract(6, "days").startOf("day").toISOString(),
      end: moment().endOf("day").toISOString(),
    },
  },
];
