import { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { UPDATE_PROJECT_CONFIG } from 'graphql/shipping/mutation';
import { PROJECT_INFO } from 'graphql/project/query';
import { notifySuccess, notifyError } from 'utils/notify';
import { ERROR_MESSAGES } from 'constants/ErrorMessages';
import { ShippingMethod } from 'types/Shipping';

interface UseSkipShippingProps {
  projectId: string;
  skipSingleShippingMethod: boolean;
  defaultShippingMethod?: ShippingMethod[];
}

export const useSkipShipping = ({ 
  projectId, 
  skipSingleShippingMethod, 
  defaultShippingMethod 
}: UseSkipShippingProps) => {
  const { t } = useTranslation();
  const [isSkipShipping, setIsSkipShipping] = useState(false);

  const enabledShippingMethods = defaultShippingMethod?.filter(
    (method: ShippingMethod) => !method.disabled
  );
  const enabledShippingMethodsCount: number = enabledShippingMethods?.length ?? 0;
  const isSkipShippingDisabled: boolean = enabledShippingMethodsCount >= 2;

  const [updateProjectConfig] = useMutation(UPDATE_PROJECT_CONFIG, {
    onCompleted: () => notifySuccess(t("Update successfully.")),
    onError: () => notifyError(t("Update failed")),
    refetchQueries: [{ query: PROJECT_INFO, variables: { projectId } }],
  });

  const updateSkipShippingConfig = async (isActive: boolean) => {
    await updateProjectConfig({
      variables: {
        projectId,
        projectInput: {
          configuration: { shipping: { skipSingleShippingMethod: { isActive } } },
        },
      },
    });
  };

  const handleToggleSkipShipping = useCallback(async () => {
    try {
      const isActive = !isSkipShipping;
      await updateSkipShippingConfig(isActive);
      setIsSkipShipping(isActive);
    } catch (error) {
      console.error(ERROR_MESSAGES.FAILED_TO_UPDATE_SKIP_SHIPPING_SETTING, error);
    }
  }, [isSkipShipping, projectId, updateProjectConfig]);

  useEffect(() => {
    if (isSkipShippingDisabled && skipSingleShippingMethod) {
      setIsSkipShipping(false);
      updateSkipShippingConfig(false);
    } else {
      setIsSkipShipping(skipSingleShippingMethod);
    }
  }, [skipSingleShippingMethod, defaultShippingMethod]);

  return {
    isSkipShipping,
    isSkipShippingDisabled,
    handleToggleSkipShipping,
  };
}; 