import { gql } from "@apollo/client";

export const CONNECT_CREDENTIAL = gql`
  query getProject($projectId: ID!) {
    getProject(projectId: $projectId) {
      facebookPageName
      id
      instagramBusinessName
      isAllowingConnectMoreChannel
      isFacebookConnected
      isInstagramConnected
      isLineConnected
      lineChannelAccessToken
      lineChannelId
      lineChannelSecret
      refId
    }
  }
`;

export const PROJECT_INFO = gql`
  query getProject($projectId: ID!) {
    getProject(projectId: $projectId) {
      configuration {
        shipping {
          shippop {
            isActive
          }
          skipSingleShippingMethod {
            isActive
          }
        }
        product {
          digitalContent {
            isActive
          }
        }
        payment {
          isAutoVerifySlip {
            isActive
          }
        }
      }
      availablePackageSubscriptionTypeToUpgrade
      botGender
      facebookPageId
      id
      instagramBusinessId
      instagramBusinessName
      isAddonPackageEnabled
      isAddonPackageEnabled
      isAdvancedConsignmentReportEnabled
      isAdvancedCustomerReportEnabled
      isAdvancedSalesReportEnabled
      isAllowApproveBySlip
      isAllowingConnectMoreChannel
      isBankTransferEnabled
      isBroadcastMessageEnabled
      isConsignmentReportEnabled
      isCustomThemeConfigurationEnabled
      isCustomerReportEnabled
      isDeeplePayEnabled
      isDisabled
      isEnterprise
      isFacebookAutoEngagementEnabled
      isGuidelineCompleted
      isInitialProjectCompleted
      isIntegrationEnabled
      isOfflineOrderEnabled
      isOpenApiConfigurationEnabled
      isPaymentGatewayAddonEnabled
      isSalesReportEnabled
      isSupportLegacyPayment
      isUsingCustomPackage
      isUsingDeeplePay
      isUsingNotSupportedPackage
      monthlyBillingPaymentIssueType
      multiStoreInventoryType
      packageEntityLevel # use to check current plan e.g. FREE,PROFESSIONAL,PREMIUM
      refId
      isChatbotEnabled
      isFacebookConnected
      isInstagramConnected
      isLineConnected
      isChatbotEnabled
      isKymApproved
      isUsingDeeplePay
      orderLimitReachedStatus
      lineChannelAccount
    }
  }
`;
