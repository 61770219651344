import { SortingRule } from "react-table";
import { MyExpressParcelFormat, OrderByType, ParcelInvoiceTemplateType } from "types/SalesReport";
import filterSelectedIds from "utils/salesReport";
import { DEFAULT_PAGE_START } from "../config";

type useHandleEventType = {
  isSalesReportEnabled?: boolean;
  projectId?: string;
  setPage: Function;
  setRowsPerPage: Function;
  setSortBy: Function;
  selectedIds?: Record<string, boolean>;
  printParcelInvoice: (projectId: string, selectedOrderIds: string[], type: ParcelInvoiceTemplateType) => void;
  printParcelMyExpress: (projectId: string, selectedOrderIds: string[], type: MyExpressParcelFormat) => void;
};

const useHandleEvent = ({
  isSalesReportEnabled = false,
  projectId = "",
  setPage,
  setRowsPerPage,
  setSortBy,
  selectedIds = {},
  printParcelInvoice,
  printParcelMyExpress,
}: useHandleEventType) => {
  const handleClickPageNo = (data: { selected: number }) => {
    const { selected } = data;

    setPage(selected + 1);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };

  const handleChangeSortBy = (sortBys: SortingRule<{ id: string; desc: boolean }>[]) => {
    const sortBy = sortBys[0];
    if (sortBy && isSalesReportEnabled) {
      setSortBy({ sortBy: sortBy.id, orderBy: sortBy.desc ? OrderByType.DESC : OrderByType.ASC });
    }
  };

  const handleClickPrintShipmentDetail = (type: ParcelInvoiceTemplateType) => {
    if (!projectId || !isSalesReportEnabled) {
      return;
    }

    const filteredSelectedOrderIds = filterSelectedIds(selectedIds);

    printParcelInvoice(projectId, filteredSelectedOrderIds, type);
  };

  const handleClickPrintParcelMyExpress = (type: MyExpressParcelFormat) => {
    if (!projectId || !isSalesReportEnabled) {
      return;
    }

    const filteredSelectedOrderIds = filterSelectedIds(selectedIds);

    printParcelMyExpress(projectId, filteredSelectedOrderIds, type);
  };

  return {
    handleChangeRowsPerPage,
    handleChangeSortBy,
    handleClickPageNo,
    handleClickPrintShipmentDetail,
    handleClickPrintParcelMyExpress,
  };
};

export default useHandleEvent;
