import { gql } from "@apollo/client";

export const UPDATE_PROJECT_CONFIG = gql`
  mutation updateProject($projectId: ID!, $projectInput: ProjectInput!) {
    updateProject(projectId: $projectId, projectInput: $projectInput) {
      configuration {
        delivery {
          schedule {
            isActive
            start
            end
            timeInAdvance
          }
        }
        shipping {
          skipSingleShippingMethod {
            isActive
          }
        }
      }
    }
  }
`;

export const UPDATE_SHIPPING_METHOD = gql`
  mutation updateDefaultShippingMethod($projectId: ID!, $shippingMethod: InputShippingMethod!) {
    updateDefaultShippingMethod(projectId: $projectId, shippingMethod: $shippingMethod) {
      image
      shipping {
        methodType
        method
        calculateType
        pricePerUnit
        minimumPrice
        maximumPrice
        fixedPrice
        minDuration
        maxDuration
        description
        disabled
        disabledDuration
        isReceiveOrderOutsideDeliveryTime
        isDisabledSchedulePickUp
        isAllowedOnlyCOD
        settings {
          name
          isActive
          price
          postalCodes {
            postalCode
            price
          }
        }
        setting {
          provinceSettings {
            name
            isActive
            price
            postalCodes {
              postalCode
              price
            }
          }
          shippopSetting {
            type
            managementFee
          }
        }
      }
      shippop {
        email
        credential {
          apiKey
        }
        senderInformation {
          name
          phoneNumber
          address
          district
          subDistrict
          province
          postalCode
          isActive
        }
      }
    }
  }
`;

export const CREATE_SHIPPOP_CONSIGNMENT_NOTE = gql`
  mutation createShippopConsignmentNote($projectId: ID!, $orderIds: [ID!]!, $size: String!, $fileType: FileType) {
    createShippopConsignmentNote(projectId: $projectId, orderIds: $orderIds, size: $size, fileType: $fileType)
  }
`;

export const CREATE_SHIPPOP_ORDER = gql`
  mutation createShippopOrder($projectId: ID!, $orderIds: [ID!]!) {
    createShippopOrder(projectId: $projectId, orderIds: $orderIds)
  }
`;
