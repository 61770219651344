import moment from "moment";
import uniq from "lodash/uniq";

import { PLATFORM } from "types";
// TODO: Do something with this local types file
import { FILTER_SELECT_TYPE, FILTER_ID, PaymentMethodType, DeeplepayPaymentMethodType, IsPrintedParcel, HasTrackingNumber } from "./types";

export const INITIAL_FILTER_ID_LISTS = [FILTER_ID.ORDER_STATE, FILTER_ID.DATE_RANGE];
export const EXCLUDED_FILTER_ID_LISTS = [...INITIAL_FILTER_ID_LISTS, FILTER_ID.CATEGORIES_ID];
export const INITIAL_FILTER_LISTS = [
  { id: FILTER_ID.ORDER_STATE, orderState: [] },
  {
    id: FILTER_ID.DATE_RANGE,
    dateRange: {
      start: moment().subtract(6, "days").startOf("day").toISOString(),
      end: moment().endOf("day").toISOString(),
    },
  },
];

/**
 * Order report platform options
 * - FACEBOOK
 * - INSTAGRAM ** feature flag **
 * - LINE
 * - OFFLINE
 */
export const CUSTOMER_PLATFORMS_MENU_OPTIONS = () => [
  PLATFORM.FACEBOOK,
  PLATFORM.INSTAGRAM,
  PLATFORM.LINE,
  PLATFORM.OFFLINE,
];

export const MENU_OPTIONS = (isSupportLegacyPayment: boolean, isDeeplePayEnabled: boolean) => {
  let paymentOptions: ReadonlyArray<string> = [];
  if (isSupportLegacyPayment) {
    paymentOptions = [...paymentOptions, ...Object.values(PaymentMethodType)];
  }
  if (isDeeplePayEnabled) {
    paymentOptions = [...paymentOptions, ...Object.values(DeeplepayPaymentMethodType)];
  }

  return [
    {
      key: FILTER_ID.PAYMENT_METHOD,
      title: FILTER_ID.PAYMENT_METHOD,
      filterKey: FILTER_ID.PAYMENT_METHOD,
      selectType: FILTER_SELECT_TYPE.STRICT_OPTIONS,
      options: uniq(paymentOptions),
    },
    {
      key: FILTER_ID.PLATFORM,
      title: FILTER_ID.PLATFORM,
      filterKey: FILTER_ID.PLATFORM,
      selectType: FILTER_SELECT_TYPE.STRICT_OPTIONS,
      options: CUSTOMER_PLATFORMS_MENU_OPTIONS(),
    },
    {
      key: FILTER_ID.PRODUCT_NAME,
      title: FILTER_ID.PRODUCT_NAME,
      filterKey: FILTER_ID.PRODUCT_NAME,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      key: FILTER_ID.CUSTOMER_NAME,
      title: FILTER_ID.CUSTOMER_NAME,
      filterKey: FILTER_ID.CUSTOMER_NAME,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      key: FILTER_ID.PAYMENT_BANK_NAME,
      title: FILTER_ID.PAYMENT_BANK_NAME,
      filterKey: FILTER_ID.PAYMENT_BANK_NAME,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      key: FILTER_ID.SHIPPING_DESCRIPTION,
      title: FILTER_ID.SHIPPING_DESCRIPTION,
      filterKey: FILTER_ID.SHIPPING_DESCRIPTION,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      key: FILTER_ID.SHIPPING_ADDRESS,
      title: FILTER_ID.SHIPPING_ADDRESS,
      filterKey: FILTER_ID.SHIPPING_ADDRESS,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      key: FILTER_ID.GRAND_TOTAL,
      title: FILTER_ID.GRAND_TOTAL,
      filterKey: FILTER_ID.GRAND_TOTAL,
      selectType: FILTER_SELECT_TYPE.NUMBER_RANGE,
      options: [],
    },
    {
      key: FILTER_ID.SHIPPING_DISCOUNT,
      title: FILTER_ID.SHIPPING_DISCOUNT,
      filterKey: FILTER_ID.SHIPPING_DISCOUNT,
      selectType: FILTER_SELECT_TYPE.NUMBER_RANGE,
      options: [],
    },
    {
      key: FILTER_ID.PRODUCT_DISCOUNT,
      title: FILTER_ID.PRODUCT_DISCOUNT,
      filterKey: FILTER_ID.PRODUCT_DISCOUNT,
      selectType: FILTER_SELECT_TYPE.NUMBER_RANGE,
      options: [],
    },
    {
      key: FILTER_ID.CUSTOMER_TAG,
      title: FILTER_ID.CUSTOMER_TAG,
      filterKey: FILTER_ID.CUSTOMER_TAG,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      id: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      key: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      title: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      filterKey: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      selectType: FILTER_SELECT_TYPE.DATE_RANGE,
    },
    {
      id: FILTER_ID.CREATED_BY,
      key: FILTER_ID.CREATED_BY,
      title: FILTER_ID.CREATED_BY,
      filterKey: FILTER_ID.CREATED_BY,
      selectType: FILTER_SELECT_TYPE.EMAIL,
      options: [],
    },
  ];
};

export const getParcelMenuOptions = () => {
  const isPrintedParcelOptions = Object.values(IsPrintedParcel);
  const hasTrackingNumberOptions = Object.values(HasTrackingNumber);

  return [
    {
      id: FILTER_ID.CARRIER_NAME,
      key: FILTER_ID.CARRIER_NAME,
      title: FILTER_ID.CARRIER_NAME,
      filterKey: FILTER_ID.CARRIER_NAME,
      selectType: FILTER_SELECT_TYPE.CUSTOM_OPTIONS,
      options: [],
    },
    {
      id: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      key: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      title: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      filterKey: FILTER_ID.DELIVERY_SCHEDULE_RANGE,
      selectType: FILTER_SELECT_TYPE.DATE_RANGE,
    },
    {
      id: FILTER_ID.PRINTED_PARCEL,
      key: FILTER_ID.PRINTED_PARCEL,
      title: FILTER_ID.PRINTED_PARCEL,
      filterKey: FILTER_ID.PRINTED_PARCEL,
      selectType: FILTER_SELECT_TYPE.STRICT_OPTIONS,
      options: uniq(isPrintedParcelOptions)
    },
    {
      id: FILTER_ID.HAS_TRACKING,
      key: FILTER_ID.HAS_TRACKING,
      title: FILTER_ID.HAS_TRACKING,
      filterKey: FILTER_ID.HAS_TRACKING,
      selectType: FILTER_SELECT_TYPE.STRICT_OPTIONS,
      options: uniq(hasTrackingNumberOptions)
    },
  ];
};
