const parcelInvoiceReport = {
  en: {
    "Parcel invoice report": "Consignment report",
    "parcelInvoiceReport.reachLimit": "Selected orders have reach {{limit}} limit",
    "unit.order": "order",
    "unit.order_plural": "orders",
    "shippop.carrier.label": "Carrier",
    "shippop.carrier.label_plural": "Carriers",
    "shippopReport.modal.noneSelectedOrder":
      "There are errors found in some orders as shown below during the invoice settlement. Please click ‘Edit’ to check the causes of errors",
    "shippopReport.modal.title": "Settle invoice(s) with Shippop",
    "shippopReport.modal.label.totalOrder": "Total orders",
    "shippopReport.modal.label.estimateTotalShipping": "Estimated shipping price",
    "shippopReport.printInvoiceButton": "Print delivery slip(s)",
    IMPORT_TRACKING_NO: "Import tracking no.",
    ORDER_STATE_IS_NOT_IN_PREPARE_TO_SHIPPING: "There is some order state is not prepared to ship\n{{orderNumbers}}",
    ORDER_NUMBER_IS_NOT_FOUND: "Order number is not found\n{{orderNumbers}}",
    PICK_UP_OR_DIGITAL_ORDER_CANNOT_UPDATE_SHIPPED_DETAIL:
      "Pick up or digital order cannot update shipping detail\n{{orderNumbers}}",
    ORDER_HAS_ONLY_DIGITAL_CONTENT_PRODUCT_CANNOT_UPDATE_SHIPPED_DETAIL:
      "Order has only digital content product cannot update shipped detail\n{{orderNumbers}}",
    NOT_SUPPORTED_SHIPPING_CALCULATION_TYPE:
      "There is some order is not supported shipping calculation type\n{{orderNumbers}}",
    "shippopReport.export.success": "Export order is successful",
    "shippopReport.export.label": "Create tracking form",
    "shippopReport.remark.insufficientLogisticCredit":
      "Insufficient Logistic Credit (remaining {{remainingLogisticCredit}})",
    SUBCEEDDED_SHIPPOP_MINIMUM_PICKUP_ORDER: "Please submit at least 10 orders to get Shippop pickup logistic service",
    "PRINTED_YES": "Printed",
    "PRINTED_NO": "Not printed",
    "HAS_TRACKING_YES": "Yes", 
    "HAS_TRACKING_NO": "No",
  },
  th: {
    "Parcel invoice report": "รายงานการนำส่งสินค้า",
    "parcelInvoiceReport.reachLimit": "เลือกรายงานการสั่งซื้อได้ไม่เกิน {{limit}}",
    "unit.order": "รายการ",
    "unit.order_plural": "รายการ",
    "shippop.carrier.label": "ผู้ให้บริการจัดส่ง",
    "shippop.carrier.label_plural": "ผู้ให้บริการจัดส่ง",
    "shippopReport.modal.noneSelectedOrder":
      "มีข้อผิดพลาดบางอย่างในระหว่างการยืนยันคำสั่งซื้อของหมายเลขออร์เดอร์ดังต่อไปนี้ โปรดคลิก 'แก้ไข' เพื่อตรวจสอบสาเหตุของข้อผิดพลาด",
    "shippopReport.modal.title": "ยืนยันคำสั่งซื้อกับ Shippop",
    "shippopReport.modal.label.totalOrder": "รายการสั่งซื้อทั้งหมด",
    "shippopReport.modal.label.estimateTotalShipping": "ค่าส่งสินค้าโดยประมาณ",
    "shippopReport.printInvoiceButton": "พิมพ์ใบนำส่ง",
    IMPORT_TRACKING_NO: "นำเข้าเลขพัสดุ",
    ORDER_STATE_IS_NOT_IN_PREPARE_TO_SHIPPING: "มีบางออร์เดอร์ไม่ได้อยู่ในสถานะเตรียมการจัดส่ง\n{{orderNumbers}}",
    ORDER_NUMBER_IS_NOT_FOUND: "ไม่พบเลขออร์เดอร์\n {{orderNumbers}}",
    PICK_UP_OR_DIGITAL_ORDER_CANNOT_UPDATE_SHIPPED_DETAIL:
      "ออร์เดอร์ที่รับด้วยตัวเองหรือดิจิทัลไม่สามารถอัปเดตรายละเอียดการจัดส่งได้\n{{orderNumbers}}",
    ORDER_HAS_ONLY_DIGITAL_CONTENT_PRODUCT_CANNOT_UPDATE_SHIPPED_DETAIL:
      "ออร์เดอร์ที่มีเพียงสินค้าดิจิทัลไม่สามารถอัปเดตรายละเอียดการจัดส่งได้\n{{orderNumbers}}",
    NOT_SUPPORTED_SHIPPING_CALCULATION_TYPE: "ไม่รองรับประเภทของการคำนวณค่าจัดส่งของออร์เดอร์บางตัว\n{{orderNumbers}}",
    "shippopReport.export.success": "นำออก ออร์เดอร์ สำเร็จ",
    "shippopReport.export.label": "สร้างไฟล์กรอกเลขพัสดุ",
    "shippopReport.remark.insufficientLogisticCredit": "ค่าจัดส่งคงเหลือไม่พอ (เหลือ {{remainingLogisticCredit}})",
    SUBCEEDDED_SHIPPOP_MINIMUM_PICKUP_ORDER:
      "กรุณาสั่ง 10 รายการสั่งซื้อขึ้นไป เพื่อใช้บริการ Shippop logistic รับพัสดุถึงบ้าน",
    "PRINTED_YES": "พิมพ์แล้ว",
    "PRINTED_NO": "ยังไม่พิมพ์",
    "HAS_TRACKING_YES": "มี",
    "HAS_TRACKING_NO": "ไม่มี",
  },
};

export default parcelInvoiceReport;
