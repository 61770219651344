import { gql } from "@apollo/client";

export { PARCEL_INVOICES_BY_ORDER_IDS } from "./parcelInvoicesByOrderIds";

export const ORDER_REPORTS = gql`
  query orderReports($projectId: ID!, $page: Int!, $pageSize: Int!, $filter: OrderReportFilterInput) {
    orderReports(projectId: $projectId, pageSize: $pageSize, page: $page, filter: $filter) {
      results {
        id
        largestDimension
        totalWeight
        shippingDescription
        note
        additionalDiscount
        orderNumber
        state
        firstName
        lastName
        referenceCode
        adminsNote
        district
        subDistrict
        province
        postalCode
        customer {
          id
          platform
          name
          displayName
          pictureUrl
        }
        email
        phoneNumber
        bill {
          billNumber
        }
        shippingMethod
        shippingPrice
        streetAddress
        streetAddress2
        trackingNumber
        isPrintedParcel
        createdBy
        vatOptions
        totalPrice
        discountTotalPrice
        discountShippingPrice
        grandTotal
        paymentAt
        orderProducts {
          id
          product {
            id
            name
            type
            images {
              src
            }
          }
          productSKU {
            id
            isFree
            productCode
            price
            weight
            image {
              src
            }
            productType {
              value
              key {
                key
              }
            }
            dimension
          }
          amount
          options {
            name
            isActive
            isMultipleChoices
            choices {
              name
              price
              isSelected
            }
          }
        }
        paymentMethodType
        deliverySchedule
        shipping {
          enDisplayName
          thDisplayName
        }
      }
      total
      summary {
        additionalDiscount
        subTotal
        shippingCost
        shippingDiscount
        productDiscount
        grandTotal
      }
    }
  }
`;

export const SHIPPOP_REPORTS = gql`
  query orderReports($projectId: ID!, $page: Int!, $pageSize: Int!, $filter: OrderReportFilterInput) {
    orderReports(projectId: $projectId, pageSize: $pageSize, page: $page, filter: $filter) {
      results {
        lastShipment {
          id
          referenceId
        }
        largestDimension
        totalWeight
        id
        note
        additionalDiscount
        orderNumber
        state
        firstName
        lastName
        referenceCode
        adminsNote
        district
        subDistrict
        province
        postalCode
        customer {
          id
          platform
          name
          pictureUrl
        }
        email
        phoneNumber
        bill {
          billNumber
        }
        shippingMethod
        shippingPrice
        shippingCalculationType
        shippingDescription
        streetAddress
        streetAddress2
        trackingNumber
        vatOptions
        totalPrice
        discountTotalPrice
        discountShippingPrice
        grandTotal
        paymentAt
        orderProducts {
          id
          product {
            id
            name
            type
            images {
              src
            }
          }
          productSKU {
            id
            isFree
            productCode
            price
            weight
            image {
              src
            }
            productType {
              value
              key {
                key
              }
            }
            dimension
          }
          amount
          options {
            name
            isActive
            isMultipleChoices
            choices {
              name
              price
              isSelected
            }
          }
        }
        paymentMethodType
        shipping {
          enDisplayName
          thDisplayName
        }
      }
      total
      summary {
        additionalDiscount
        subTotal
        shippingCost
        shippingDiscount
        productDiscount
        grandTotal
      }
    }
  }
`;

export const EXPORT_ORDER_REPORTS = gql`
  query exportOrderReports(
    $projectId: ID!
    $isSelectAll: Boolean!
    $selectedOrderIds: [ID]
    $excludeOrderIds: [ID]
    $filter: OrderReportFilterInput
    $exportFileType: ExportFileType
  ) {
    exportOrderReports(
      projectId: $projectId
      isSelectAll: $isSelectAll
      selectedOrderIds: $selectedOrderIds
      excludeOrderIds: $excludeOrderIds
      exportFileType: $exportFileType
      filter: $filter
    ) {
      url
    }
  }
`;

export const ORDER_PRODUCT_REPORTS = gql`
  query orderProductReports($projectId: ID!, $pageSize: Int!, $page: Int!, $filter: OrderProductReportFilterInput) {
    orderProductReports(projectId: $projectId, pageSize: $pageSize, page: $page, filter: $filter) {
      results {
        productSKUId
        product {
          id
          name
          type
          images {
            src
          }
        }
        productSKU {
          id
          isFree
          productCode
          price
          weight
          image {
            src
          }
          productType {
            value
            key {
              key
            }
          }
          options {
            name
            isActive
            isMultipleChoices
            choices {
              name
              price
              isSelected
            }
          }
        }
        categoryName
        price
        productSKUCode
        total
        amount
      }
      total
      summary {
        totalSoldAmount
        totalPrice
      }
    }
  }
`;

export const EXPORT_PRODUCT_REPORTS = gql`
  query exportOrderProductReports(
    $projectId: ID!
    $isSelectAll: Boolean!
    $selectedProductSKUIds: [ID]
    $excludeProductSKUIds: [ID]
    $filter: OrderProductReportFilterInput
    $exportFileType: ExportFileType
  ) {
    exportOrderProductReports(
      projectId: $projectId
      isSelectAll: $isSelectAll
      selectedProductSKUIds: $selectedProductSKUIds
      excludeProductSKUIds: $excludeProductSKUIds
      exportFileType: $exportFileType
      filter: $filter
    ) {
      url
    }
  }
`;

export const RECEIPT_URL = gql`
  query receiptURL($projectId: ID!, $orderId: ID!) {
    receiptURL(projectId: $projectId, orderId: $orderId)
  }
`;
