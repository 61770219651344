import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CellProps, Row, useSortBy, useFlexLayout, useExpanded } from "react-table";
import get from "lodash/get";
import moment from "moment";

import { PAYMENT_METHOD_TYPES } from "constants/Payment";
import { COLOR_BY_STATUS } from "constants/Order";
import { ORDER_STATUSES } from "constants/SalesReport";
import { CUSTOMER_NOTE_DATE_TIME_FORMAT } from "constants/DateTimeFormat";
import { convertPriceFormat } from "utils/common";
import i18n from "utils/i18n";
import IndeterminateCheckbox from "components/Checkbox";
import CircularProgress from "components/CircularProgress";
import { SvgIcon } from "components/Icon";
import { IcDownload } from "components/SvgIcons";
import Typography from "components/Typography";
import { useExpandButton } from "./hooks";
import { Bullet, Thumbnail, ThumbnailWrapper, OrderNo, Status } from "./styled";
import { DEFAULT_VALUE, EXPAND_CELL_WIDTH } from "../config";

export const tableHooks = [useSortBy, useExpanded, useFlexLayout, useExpandButton];

const CHECKBOX_COLUMN_WIDTH = 56;

const Capsule = styled.div`
  background-color: ${({ theme }) => theme.COLORS.LightGray};
  border-radius: 8px;
  padding: 4px 6px;
  margin-right: 4px;
  margin-left: 4px;
`;

export const getColumns = <T extends object>(
  projectId?: string,
  onClickDownloadPayslip?: Function,
  isLoading?: boolean,
  downloadOrderId?: string,
  onSelectRow?: (id: string) => void,
  onSelectAllRowInPage?: (ids: string[], isSelectedSomeRow: boolean) => void,
) => {
  return [
    {
      id: "selection",
      width: CHECKBOX_COLUMN_WIDTH,
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/display-name
      Header: (row: Row<T>) => {
        const allData: (T & { id: string })[] = get(row, "data") || [];
        const ids = allData.map((data) => data.id);
        const isSelectedAll: boolean = get(row, `state.isSelectedAll`) || false;
        const excludeIds: Record<string, boolean> = get(row, `state.excludeIds`) || {};
        const selectedIds: Record<string, boolean> = get(row, `state.selectedIds`) || {};
        const isSelectedSomeRow = isSelectedAll ? ids.some((id) => excludeIds[id]) : ids.some((id) => !selectedIds[id]);

        return (
          <div className="m-auto">
            <IndeterminateCheckbox
              onClick={() => {
                if (onSelectAllRowInPage && ids.length) {
                  onSelectAllRowInPage(ids, isSelectedSomeRow);
                }
              }}
              checked={!isSelectedSomeRow}
            />
          </div>
        );
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const id = get(cell, "row.original.id");
        const isSelected = get(cell, `state.selectedIds[${id}]`, false);
        const isExcluded = get(cell, `state.excludeIds[${id}]`, false);
        const isSelectedAll = get(cell, `state.isSelectedAll`, false);
        let isChecked = false;

        if (isSelectedAll) {
          isChecked = !isExcluded;
        } else {
          isChecked = isSelected;
        }

        return (
          <div className="m-auto">
            <IndeterminateCheckbox
              onClick={() => {
                if (onSelectRow && id) {
                  onSelectRow(id);
                }
              }}
              checked={isChecked}
            />
          </div>
        );
      },
    },
    {
      Header: i18n.t("Order no."),
      accessor: "orderNumber",

      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => (
        <OrderNo>
          {projectId ? <Link to={`/project/${projectId}/orders/${cell.value}`}>{cell.value}</Link> : cell.value}
        </OrderNo>
      ),
    },
    {
      Header: i18n.t("Customer"),
      accessor: "customerName",
    },
    {
      disableSortBy: true,
      Header: i18n.t("Status"),
      accessor: "state",
      width: 200,

      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const paymentMethodType = get(cell, "row.original.paymentMethodType");

        const isCODStatus = paymentMethodType === PAYMENT_METHOD_TYPES.CASH_ON_DELIVERY;

        return (
          <>
            <Bullet color={COLOR_BY_STATUS[cell.value]} />
            <Status color={COLOR_BY_STATUS[cell.value]}>
              {typeof cell.value === "string" ? i18n.t(ORDER_STATUSES[cell.value]) : cell.value}
            </Status>
            {isCODStatus && (
              <Capsule>
                <Typography variant="iconText" color="black">
                  COD
                </Typography>
              </Capsule>
            )}
          </>
        );
      },
    },
    {
      Header: i18n.t("Delivery scheduled"),
      accessor: "deliverySchedule",
      disableSortBy: true,
      Cell: (cell: CellProps<T>) => {
        const deliverySchedule = cell.value ? moment(Number(cell.value)).format(CUSTOMER_NOTE_DATE_TIME_FORMAT) : "-";
        return deliverySchedule;
      },
    },
    {
      Header: i18n.t("carrierName"),
      accessor: "shippingDescription",
      disableSortBy: true,
      Cell: (cell: CellProps<T>) => {
        return i18n.t(`${cell.value}`);
      },
    },
    { disableSortBy: true, Header: i18n.t("Address"), accessor: "streetAddress", width: 250 },
    { disableSortBy: true, Header: i18n.t("Note"), accessor: "note" },
    { disableSortBy: true, Header: i18n.t("PhoneNumber"), accessor: "phoneNumber" },
    { disableSortBy: true, Header: i18n.t("E-mail"), accessor: "email" },
    { disableSortBy: true, Header: i18n.t("Tracking No."), accessor: "trackingNumber" },
    {
      disableSortBy: true,
      Header: i18n.t("isPrintedParcel"),
      accessor: "isPrintedParcel",
      Cell: (cell: CellProps<T>) => {
        return cell.value ? i18n.t("PRINTED_YES") : '-';
      },
    },
    {
      Header: i18n.t("PaySlip"),
      accessor: "billNumber",
      disableSortBy: true,
      // eslint-disable-next-line react/display-name
      Cell: (cell: CellProps<T>) => {
        const orderId = get(cell.value, "orderId");
        const billNumber = get(cell.value, "billNumber");

        return (
          <>
            {billNumber && (
              <>
                {isLoading && downloadOrderId === orderId ? (
                  <CircularProgress size={14} />
                ) : (
                  <div
                    className="d-flex"
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickDownloadPayslip && onClickDownloadPayslip(projectId, orderId)}
                  >
                    <SvgIcon component={IcDownload} fontSize="small" />
                    {billNumber}
                  </div>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];
};

export const getSubTableColumns = <T extends object>(isDesktop?: boolean) => [
  {
    Header: "",
    accessor: "empty1",
    width: isDesktop ? 34 : 56,
  },
  {
    Header: "#",
    accessor: "image",

    width: 47,
    // eslint-disable-next-line react/display-name
    Cell: (cell: CellProps<T>) => {
      return cell.value ? (
        <ThumbnailWrapper>
          <Thumbnail alt="product" src={cell.value} />
        </ThumbnailWrapper>
      ) : (
        ""
      );
    },
  },
  {
    Header: i18n.t("SKU CODE"),
    accessor: "productCode",

    width: 94,
  },
  {
    Header: i18n.t("PRODUCT NAME"),
    accessor: "productName",
  },
  { Header: i18n.t("Variation/Option"), accessor: "sku" },
  { Header: i18n.t("AMOUNT"), accessor: "amount" },
  {
    Header: i18n.t("WEIGHT (g.)"),
    accessor: "weight",
    Cell: (cell: CellProps<T>) => {
      const weight = cell.value;
      if (weight == null) {
        return DEFAULT_VALUE;
      }

      return `${typeof weight === "number" ? convertPriceFormat(weight) : weight}`;
    },
  },
  {
    Header: "",
    accessor: "empty",
    width: EXPAND_CELL_WIDTH,
  },
];
