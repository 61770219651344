import React from 'react';
import Grid from "components/Grid";
import Typography from "components/Typography";
import Switch from "components/Switch";
import { useTranslation } from "react-i18next";

type ActiveSkipShippingProps = {
  isSkipShipping: boolean;
  onClick: () => void;
  disabled: boolean;
};

export const ActiveSkipShipping = ({ isSkipShipping, onClick, disabled }: ActiveSkipShippingProps) => {
  const { t } = useTranslation();
  return (
    <Grid container item alignItems="center" xs={12} className="ml-2">
      <Switch
        checked={isSkipShipping}
        onChange={onClick}
        disabled={disabled}
      />
      <Typography
        variant="body2"
        className="text-gray-700 ml-3"
      >
        {t("Skip shipping selection")}
      </Typography>
    </Grid>
  );
};
