import { OrderReportResult } from "types/Report";
import { convertPriceFormat } from "utils/common";
import { getCustomerAddressText } from "utils/address";
import { getCourierNameByShippingMethod } from "utils/shipping";
import { SubTableDataType, formattedSubTable } from "./formattedSubTable";
import { DEFAULT_VALUE } from "../config";

export type OrderReportsDataType = {
  id: string;
  orderNumber: string;
  customerName: string;
  state: string;
  shippingMethod: string;
  shippingPrice: string;
  streetAddress: string;
  phoneNumber: string;
  paymentMethodType: string;
  email: string;
  trackingNumber: string;
  subTable: SubTableDataType[];
  isPrintedParcel: boolean;
};

export const formattedOrderReports = (
  projectId: string,
  orderReportsInput: OrderReportResult[],
): OrderReportsDataType[] => {
  return orderReportsInput.map((order) => {
    const streetAddress = order?.streetAddress || "";
    const streetAddress2 = order?.streetAddress2 || "";
    const subDistrict = order?.subDistrict || "";
    const district = order?.district || "";
    const province = order?.province || "";
    const postalCode = order?.postalCode || "";

    const id: string = order?.id || DEFAULT_VALUE;
    const orderNumber: string = order?.orderNumber || DEFAULT_VALUE;
    const customerName: string = order?.customer.name || DEFAULT_VALUE;
    const state: string = order?.state || DEFAULT_VALUE;
    const paymentMethodType: string = order?.paymentMethodType || DEFAULT_VALUE;
    const shippingMethod: string = order?.shippingMethod || DEFAULT_VALUE;
    const shippingPrice: string = convertPriceFormat(order?.shippingPrice || 0);
    const longAddress: string =
      getCustomerAddressText(
        {
          streetAddress,
          streetAddress2,
          district,
          subDistrict,
          province,
          postalCode,
        },
        [],
      ) || DEFAULT_VALUE;

    const note: string = order?.note || DEFAULT_VALUE;
    const phoneNumber: string = order?.phoneNumber || DEFAULT_VALUE;
    const email: string = order?.email || DEFAULT_VALUE;
    const trackingNumber: string = order?.trackingNumber || DEFAULT_VALUE;
    const isPrintedParcel: boolean = order?.isPrintedParcel || false;
    const deliverySchedule: string = order?.deliverySchedule;

    return {
      customerName,
      deliverySchedule,
      email,
      id,
      note,
      orderNumber,
      paymentMethodType,
      phoneNumber,
      shippingMethod,
      shippingPrice,
      state,
      trackingNumber,
      isPrintedParcel,
      billNumber: {
        projectId,
        billNumber: order?.bill?.billNumber,
        orderId: order?.id,
      },
      shippingDescription: getCourierNameByShippingMethod(order?.shippingDescription, order.shipping),
      streetAddress: longAddress,
      subTable: formattedSubTable(order?.orderProducts || []),
    };
  });
};
