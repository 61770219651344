import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Modal from "components/Modal";
import Button from "components/Button";
import Select from "components/Select";
import { COLORS } from "constants/Colors";
import { MyExpressParcelFormat } from "types/SalesReport";
import { ModalContent, PreviewContainer, LabelPreview, SelectWrapper } from "./style";

type ModalSelectLabelFormatProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (format: MyExpressParcelFormat) => void;
};

type FormatOption = { value: MyExpressParcelFormat; label: string };

const formatDimensions = {
  [MyExpressParcelFormat.TSC_100_75]: { width: 100, height: 75 },
  [MyExpressParcelFormat.TSC_100_100]: { width: 100, height: 100 },
  [MyExpressParcelFormat.TSC_100_150]: { width: 100, height: 150 },
  [MyExpressParcelFormat.TSC_100_180]: { width: 100, height: 180 },
  [MyExpressParcelFormat.MINI_57_100]: { width: 57, height: 100 },
};

const ModalSelectLabelFormat: FC<ModalSelectLabelFormatProps> = ({
  isOpen = false,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedFormat, setSelectedFormat] = useState<MyExpressParcelFormat>(MyExpressParcelFormat.TSC_100_75);

  const formatOptions = [
    { value: MyExpressParcelFormat.TSC_100_75, label: "TSC_100_75" },
    { value: MyExpressParcelFormat.TSC_100_100, label: "TSC_100_100" },
    { value: MyExpressParcelFormat.TSC_100_150, label: "TSC_100_150" },
    { value: MyExpressParcelFormat.TSC_100_180, label: "TSC_100_180" },
    { value: MyExpressParcelFormat.MINI_57_100, label: "MINI_57_100" },
  ] as FormatOption[];

  const handleSubmit = () => {
    onSubmit(selectedFormat);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeIconColor={COLORS.DarkGray}>
      <ModalContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="title2" color="dark" className="text-center">
              {t("salesReport.modalSelectLabelFormat.title")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SelectWrapper>
              <Select<FormatOption>
                value={formatOptions.find(option => option.value === selectedFormat)}
                options={formatOptions}
                onChange={(option) => option && setSelectedFormat(option?.value)}
              />
            </SelectWrapper>
          </Grid>

          <Grid item xs={12}>
            <PreviewContainer>
              <LabelPreview 
                width={formatDimensions[selectedFormat].width} 
                height={formatDimensions[selectedFormat].height}
              />
            </PreviewContainer>
          </Grid>

          <Grid item xs={6}>
            <Button 
              size="medium" 
              color="secondary" 
              fullWidth 
              onClick={onClose}
            >
              {t("salesReport.modalSelectLabelFormat.cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
              size="medium" 
              fullWidth 
              onClick={handleSubmit}
            >
              {t("salesReport.modalSelectLabelFormat.print")}
            </Button>
          </Grid>
        </Grid>
      </ModalContent>
    </Modal>
  );
};

export default ModalSelectLabelFormat; 