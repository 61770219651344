import { BroadcastStatus } from "types/Broadcast";
import uniq from "lodash/uniq";
import {
  FilterItemType,
  FILTER_SELECT_TYPE,
  OrderStateType,
  PaymentMethodType,
  DeeplepayPaymentMethodType,
  PlatformType,
  ShippingMethodType,
  FILTER_ID,
  IsPrintedParcel,
  HasTrackingNumber,
} from "./types";

export const displayFilterName = (itemFilter: FilterItemType) => {
  let filterName = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(itemFilter)) {
    if (key !== "id") {
      filterName = `${key} : ${value}`;
    }
  }
  return filterName;
};

export const getKeyName = (itemFilter: FilterItemType): FILTER_ID | string => {
  let keyName = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const [key] of Object.entries(itemFilter)) {
    if (key !== "id") {
      keyName = `${key}`;
    }
  }
  return keyName;
};

export const getFilterValue = (itemFilter: FilterItemType) => {
  let itemValue = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(itemFilter)) {
    if (key !== "id") {
      itemValue = value;
    }
  }

  return itemValue;
};

export const getSelectTypeFromFilterKey = (filterItem: FilterItemType) => {
  let filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
  switch (getKeyName(filterItem)) {
    case FILTER_ID.ORDER_STATE:
      filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
      break;
    case FILTER_ID.SHIPPING_METHOD:
      filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
      break;
    case FILTER_ID.PAYMENT_METHOD:
      filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
      break;
    case FILTER_ID.PLATFORM:
      filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
      break;
    case FILTER_ID.PRODUCT_NAME:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.CUSTOMER_NAME:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.PAYMENT_BANK_NAME:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.SHIPPING_DESCRIPTION:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.SHIPPING_ADDRESS:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.GRAND_TOTAL:
      filterSelectType = FILTER_SELECT_TYPE.NUMBER_RANGE;
      break;
    case FILTER_ID.SHIPPING_DISCOUNT:
      filterSelectType = FILTER_SELECT_TYPE.NUMBER_RANGE;
      break;
    case FILTER_ID.PRODUCT_DISCOUNT:
      filterSelectType = FILTER_SELECT_TYPE.NUMBER_RANGE;
      break;
    case FILTER_ID.DATE_RANGE:
      filterSelectType = FILTER_SELECT_TYPE.DATE_RANGE;
      break;
    case FILTER_ID.DELIVERY_SCHEDULE_RANGE:
      filterSelectType = FILTER_SELECT_TYPE.DATE_RANGE;
      break;
    case FILTER_ID.PRODUCT_SKU_CODE:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.PRICE:
      filterSelectType = FILTER_SELECT_TYPE.NUMBER_RANGE;
      break;
    case FILTER_ID.TOTAL:
      filterSelectType = FILTER_SELECT_TYPE.NUMBER_RANGE;
      break;
    case FILTER_ID.CATEGORIES_ID:
      filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
      break;
    case FILTER_ID.CUSTOMER_TAG:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOMER_TAG;
      break;
    case FILTER_ID.BROADCAST_STATUS:
      filterSelectType = FILTER_SELECT_TYPE.STRICT_OPTIONS;
      break;
    case FILTER_ID.CARRIER_NAME:
      filterSelectType = FILTER_SELECT_TYPE.CUSTOM_OPTIONS;
      break;
    case FILTER_ID.CREATED_BY:
      filterSelectType = FILTER_SELECT_TYPE.EMAIL;
      break;

    default:
      break;
  }
  return filterSelectType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOptionsFromKey = (
  filterItem: FilterItemType,
  filterOptions: any,
  suggestOptions: any,
  isSupportLegacyPayment?: boolean,
  isDeeplePayEnabled?: boolean,
) => {
  let filterOptionType = null;
  switch (getKeyName(filterItem)) {
    case FILTER_ID.ORDER_STATE:
      filterOptionType = Object.values(OrderStateType);
      break;
    case FILTER_ID.SHIPPING_METHOD:
      filterOptionType = Object.values(ShippingMethodType);
      break;
    case FILTER_ID.PAYMENT_METHOD:
      filterOptionType = [] as ReadonlyArray<string>;
      if (isSupportLegacyPayment) {
        filterOptionType = [...filterOptionType, ...Object.values(PaymentMethodType)];
      }
      if (isDeeplePayEnabled) {
        filterOptionType = [...filterOptionType, ...Object.values(DeeplepayPaymentMethodType)];
      }
      filterOptionType = uniq(filterOptionType);
      break;
    case FILTER_ID.PLATFORM:
      filterOptionType = Object.values(PlatformType);
      break;
    case FILTER_ID.PRODUCT_NAME:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.CUSTOMER_NAME:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.PAYMENT_BANK_NAME:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.SHIPPING_DESCRIPTION:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.SHIPPING_ADDRESS:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.GRAND_TOTAL:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.SHIPPING_DISCOUNT:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.PRODUCT_DISCOUNT:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.DATE_RANGE:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.DELIVERY_SCHEDULE_RANGE:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.PRODUCT_SKU_CODE:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.PRICE:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.TOTAL:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.CATEGORIES_ID:
      filterOptionType = suggestOptions;
      break;
    case FILTER_ID.CUSTOMER_TAG:
      filterOptionType = suggestOptions;
      break;
    case FILTER_ID.BROADCAST_STATUS:
      filterOptionType = Object.values(BroadcastStatus);
      break;
    case FILTER_ID.CARRIER_NAME:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.CREATED_BY:
      filterOptionType = filterOptions;
      break;
    case FILTER_ID.PRINTED_PARCEL:
      filterOptionType = Object.values(IsPrintedParcel);    
      break;
    case FILTER_ID.HAS_TRACKING:
      filterOptionType = Object.values(HasTrackingNumber);
      break;
    default:
      break;
  }
  return filterOptionType;
};
