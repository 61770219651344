import { ProductTypeType, ProductOptionType, ProductItemPropsType, ProductImagesDefaultType } from "types/Product";

type OrderProductReportSummaryType = {
  totalSoldAmount: number;
  totalPrice: number;
};

type ProductReportSKUType = {
  id: string;
  isFree: boolean;
  productCode: string;
  price: number;
  weight: number;
  image: ProductImagesDefaultType;
  productType: ProductTypeType;
  options: ProductOptionType[];
};

export type OrderProductReportResultType = {
  productSKUId: string;
  product: ProductItemPropsType;
  productSKU: ProductReportSKUType;
  categoryName: string;
  price: number;
  productSKUCode: string;
  total: number;
  amount: number;
};

type OrderProductReportType = {
  result: OrderProductReportResultType[];
  total: number;
  summary: OrderProductReportSummaryType;
};

export type OrderProductReportQueryType = {
  orderProductReports: OrderProductReportType;
};

type DateRangeType = {
  start: string;
  end: string;
};

type ReportVariableFilterType = {
  categoryId: string[];
  dateRange: DateRangeType;
};

export type ReportQueryVariableType = {
  page: number;
  pageSize: number;
  projectId: string;
  filter: ReportVariableFilterType | {};
};

export enum OrderByType {
  DESC = "DESC",
  ASC = "ASC",
}

export type Data = Record<string, number | string | string[] | object | object[]>;

export type OrderReportType = {
  orderNumber: string;
  customerName: string;
  state: string;
  shippingMethod: string;
  shippingPrice: number;
  streetAddress: string;
  phoneNumber: string;
  email: string;
  trackingNumber: string;
  vatOptions: string;
  totalPrice: number;
  discountTotalPrice: number;
  grandTotal: number;
  paymentAt: string;
  subTable: ProductSKUInOrderReportType[];
};

export type ProductSKUInOrderReportType = {
  productName: string;
  image: string;
  productCode: string;
  productPrice: number;
  amount: number;
  weight: number;
  sku: string;
  subRows: SKUInOrderReportType[];
};

export type SKUInOrderReportType = {
  sku: string;
  productPrice: number;
  amount: number;
};

export enum ExportFileType {
  CSV = "CSV",
  XLSX = "XLSX",
}

export enum ParcelInvoiceTemplateType {
  FULL = "FULL",
  COMPACT = "COMPACT",
}

export enum MyExpressParcelFormat {
  TSC_100_75 = "TSC_100_75",
  TSC_100_100 = "TSC_100_100",
  TSC_100_150 = "TSC_100_150",
  TSC_100_180 = "TSC_100_180",
  MINI_57_100 = "MINI_57_100",
}