import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import print from "print-js";
import get from "lodash/get";
import { PRINT_PARCEL_MY_EXPRESS_BY_ORDER_IDS } from "graphql/salesReport/query/printParcelMyExpress";
import { MyExpressParcelFormat } from "types/SalesReport";

type UsePrintParcelMyExpressDataType = {
  data: {
    printParcelMyExpressByOrderIds: string[];
  };
};

const usePrintParcelMyExpress = () => {
  const previousSelectedOrderId = useRef<string[]>();
  const previousSelectedConsignmentType = useRef<MyExpressParcelFormat>();
  const [getPrintParcelMyExpress, { data, loading }] = useLazyQuery<UsePrintParcelMyExpressDataType>(
    PRINT_PARCEL_MY_EXPRESS_BY_ORDER_IDS,
  );

  const pdfData = get(data, "printParcelMyExpressByOrderIds");

  useEffect(() => {
    if (!loading && pdfData?.length) {
      try {
        const uint8Array = new Uint8Array(pdfData.map((num: string) => parseInt(num, 10)));
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        
        print(url);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error printing PDF:', error);
      }
    }
  }, [loading, pdfData]);

  const printParcelMyExpress = async (projectId: string, selectedOrderIds: string[], size: string) => {
    if (!selectedOrderIds.length) {
      return;
    }

    if (
      previousSelectedOrderId.current &&
      previousSelectedConsignmentType.current &&
      isEqual(previousSelectedOrderId.current, selectedOrderIds) &&
      isEqual(previousSelectedConsignmentType.current, size)
    ) {
      try {
        const uint8Array = new Uint8Array(pdfData.map((num: string) => parseInt(num, 10)));
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        print(url);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error printing PDF:', error);
      }
      return;
    }

    previousSelectedOrderId.current = selectedOrderIds;
    previousSelectedConsignmentType.current = size as MyExpressParcelFormat;

    getPrintParcelMyExpress({
      variables: {
        projectId,
        orderIds: selectedOrderIds,
        size,
      },
    });
  };

  return { printParcelMyExpress, loading };
};

export default usePrintParcelMyExpress;